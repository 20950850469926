<template>
  <PageContainer v-if="fields" class="bg-mint">
    <template #header>
      <PageShingleWrapper class="bg-mint" next-class="bg-mint-light">
        <DefaultGrid class="pb-56 pt-20 md:pb-80 lg:pb-52 lg:pt-16">
          <div
            class="col-span-full flex flex-col items-center justify-center space-y-16 md:space-y-24"
          >
            <div
              class="flex min-h-[2.85em] items-start justify-center text-[16vw] md:min-h-[2.5em]"
            >
              <TransitionGroup
                :css="false"
                appear
                @enter="onEnter"
                @leave="onLeave"
              >
                <LockupDoomScrolling v-if="headerCarouselIndex === 0" />
                <LockupScreenAddiction v-else-if="headerCarouselIndex === 1" />
                <LockupCyberBullying v-else-if="headerCarouselIndex === 2" />
                <LockupHarmfulContent v-else-if="headerCarouselIndex === 3" />
              </TransitionGroup>
            </div>
            <div
              class="text-center transition-opacity delay-200 duration-500"
              :class="showContent ? '' : 'opacity-0'"
            >
              <NuxtLink to="/pledge" class="btn btn-lg btn-yellow">
                Take the pledge
              </NuxtLink>
            </div>
          </div>
        </DefaultGrid>
      </PageShingleWrapper>
    </template>
    <template #main>
      <section class="bg-mint-light">
        <DefaultGrid
          class="space-y-16 pb-32 md:pb-48 lg:space-y-0 lg:pt-32 xl:pt-40"
        >
          <div
            class="col-span-full flex h-0 items-end pt-[50%] md:col-start-2 md:col-end-8 lg:order-2 lg:col-span-7 lg:pt-[60%] xl:col-span-6 xl:pt-[50%]"
          >
            <div
              class="mask-blob trnasition-opacity mx-auto aspect-square max-w-96 delay-500 duration-500 md:max-w-3xl"
              :class="showContent ? '' : 'opacity-0'"
            >
              <PayloadImage
                class="h-full w-full object-cover"
                :image="fields.intro.image"
                :sizes="{
                  default: '90vw',
                  md: '68vw',
                  lg: '52vw',
                  xl: '44vw',
                  '2xl': '768px',
                }"
              />
            </div>
          </div>
          <div
            class="col-span-full space-y-12 text-center lg:order-1 lg:col-span-5 lg:text-left xl:col-start-2 xl:col-end-7"
          >
            <div class="rich-text type-heading-4">
              <PayloadRichText :content="fields.intro.body" />
            </div>
            <NuxtLink to="/about" class="btn btn-sm btn-black">
              Learn more about Wait Mate
            </NuxtLink>
          </div>
        </DefaultGrid>
      </section>
      <section
        class="space-y-12 bg-white pt-20 md:space-y-14 md:pt-24 lg:pt-28 xl:space-y-20 xl:pt-32"
      >
        <DefaultGrid>
          <div class="col-span-full text-center">
            <h2 class="type-heading-2" v-text="fields.whySection.heading" />
          </div>
        </DefaultGrid>
        <div>
          <LargeScrollCards :cards="fields.whySection.cards" />
          <div class="text-center">
            <NuxtLink to="/why-wait" class="btn btn-sm btn-black">
              More reasons to wait
            </NuxtLink>
          </div>
        </div>
        <DefaultGrid>
          <div class="col-span-full">
            <BaseMarqueeWrapper overflow :speed="25">
              <div class="inline-flex items-start space-x-4">
                <div
                  v-for="{ image } in fields.whySection.carousel"
                  :key="getRelationshipID(image)"
                  class="w-44 overflow-hidden rounded-md md:w-80 md:rounded-lg xl:rounded-xl"
                >
                  <PayloadImage
                    :image="image"
                    :sizes="{
                      default: '176px',
                      md: '320px',
                    }"
                  />
                </div>
              </div>
            </BaseMarqueeWrapper>
          </div>
        </DefaultGrid>
      </section>
      <section class="bg-white">
        <DefaultGrid
          class="step-cards space-y-12 pb-24 pt-20 md:space-y-14 md:pt-24 lg:pt-28 xl:space-y-20 xl:pt-32"
        >
          <div class="col-span-full space-y-8 text-center md:space-y-10">
            <h2 class="type-heading-2" v-text="fields.joinSection.heading" />
            <p class="mx-auto max-w-2xl" v-text="fields.joinSection.body" />
          </div>
        </DefaultGrid>
        <SmallScrollCards :cards="fields.joinSection.stepCards" />
      </section>
      <section>
        <PageShingleWrapper
          class="relative overflow-hidden bg-grey"
          next-class="bg-mint"
        >
          <div class="absolute -inset-x-[20%] top-0">
            <SvgScribble
              class="-mt-[50%] hidden h-auto w-full text-black/5 md:block"
            />
          </div>
          <DefaultGrid
            class="relative z-[2] space-y-14 py-20 md:space-y-16 md:py-24 lg:space-y-20 xl:space-y-24"
          >
            <div class="col-span-full">
              <h2 class="type-heading-2 text-center">Resources</h2>
            </div>
            <div class="col-span-full">
              <BaseSlider :options="sliderOptions">
                <ResourceArticleCard
                  v-for="resourceArticle in resourceArticleData
                    ?.ResourceArticles.docs"
                  :key="resourceArticle.id"
                  class="min-w-[310px] max-w-[310px] md:min-w-[400px] md:max-w-[400px]"
                  :resource-article="resourceArticle"
                />
              </BaseSlider>
            </div>
            <div class="col-span-full text-center">
              <NuxtLink class="btn btn-sm btn-black" to="/resources"
                >View more</NuxtLink
              >
            </div>
          </DefaultGrid>
        </PageShingleWrapper>
      </section>
      <PageCtaSection />
    </template>
  </PageContainer>
</template>

<script lang="ts" setup>
import type { PaginatedDocs } from 'payload/database'
import type { ResourceArticle } from '#payload/types'

import { GetResourceArticlesDocument } from '@/graphql'
import type { KeenSliderOptions } from 'keen-slider/vue'

interface ResourceArticlesQueryResult {
  ResourceArticles: PaginatedDocs<ResourceArticle>
}

const [initialData, { data: resourceArticleData }] = await Promise.all([
  usePayloadPage('Home'),
  useAsyncQuery<ResourceArticlesQueryResult>(GetResourceArticlesDocument, {
    limit: 3,
  }),
])
const { data: doc } = useLivePreview({ initialData })

const fields = doc.value?.homeFields

/**
 * Header Carousel
 */
const headerCarouselIndex = ref(-1)
const showContent = ref(false)
const timeoutID = ref<NodeJS.Timeout>()

watch(headerCarouselIndex, () => {
  timeoutID.value = setTimeout(() => {
    headerCarouselIndex.value = (headerCarouselIndex.value + 1) % 4
  }, 3500)
})

onMounted(() => {
  showContent.value = true
  headerCarouselIndex.value = 0
})

onBeforeUnmount(() => {
  clearTimeout(timeoutID.value)
})

const { $anime } = useNuxtApp()

const onEnter = (root: Element, complete: () => void) => {
  const anim = $anime.timeline({ complete })
  const largeText = root.querySelectorAll('.large-text span')
  const icons = root.querySelectorAll('.icons svg')

  anim
    .add(
      {
        targets: largeText,
        translateY: ['25%', 0],
        translateZ: 0,
        opacity: [0, 1],
        duration: 650,
        delay: $anime.stagger(150),
        easing: slideEasing,
      },
      500,
    )
    .add(
      {
        targets: shuffleArray([...icons]),
        scale: [0.5, 1],
        opacity: [0, 1],
        duration: 750,
        delay: $anime.stagger(250),
        easing: slideEasing,
      },
      1000,
    )
}

const onLeave = (root: Element, complete: () => void) => {
  const anim = $anime.timeline({ complete })
  const largeText = root.querySelectorAll('.large-text span')
  const icons = root.querySelectorAll('.icons svg')

  ;(root as HTMLElement).style.position = 'absolute'

  anim.add({
    targets: [largeText, icons],
    opacity: 0,
    duration: 500,
    easing: slideEasing,
  })
}

/**
 * Resource Article Slider
 */
const sliderOptions: KeenSliderOptions = {
  mode: 'snap',
  slides: {
    perView: 1.1,
    spacing: 28,
  },
  breakpoints: {
    '(min-width: 768px)': {
      slides: {
        perView: 1.6,
        spacing: 56,
      },
    },
    '(min-width: 1024px)': {
      slides: {
        perView: 3,
        spacing: 22,
      },
    },
  },
}
</script>
