<template>
  <div class="relative">
    <h1 class="flex flex-col items-center space-y-12 text-center font-medium">
      <span class="type-heading-4 uppercase"
        >Hit pause on smartphones and</span
      >
      <span class="relative">
        <span
          class="large-text type-font-salt rotate-2 leading-[0.85em] text-charcoal"
          ><span>screen</span><br />
          <span>addiction</span></span
        >
        <span class="icons pointer-events-none absolute inset-0">
          <PayloadIcon
            class="absolute bottom-[0.8em] left-[-0.1em] h-[0.39em] text-yellow-light"
            icon="SvgIconChatBubbles"
          />
          <PayloadIcon
            class="absolute bottom-[1.55em] left-[1.4em] h-[0.35em] text-red-light"
            icon="SvgIconSaturn"
          />
          <PayloadIcon
            class="absolute bottom-[1.5em] left-[2.9em] h-[0.35em] rotate-[15deg] text-yellow"
            icon="SvgIconStar"
          />
          <PayloadIcon
            class="absolute bottom-[0.65em] left-[3.85em] h-[0.3em] rotate-[8deg] text-red-light"
            icon="SvgIconInstagram"
          />
          <PayloadIcon
            class="absolute -bottom-[0.35em] left-[0.65em] h-[0.3em] text-blue-dark"
            icon="SvgIconEmbarrassed"
          />
        </span>
      </span>
    </h1>
  </div>
</template>

<style scoped lang="postcss">
span {
  @apply inline-block;
}
</style>
