<template>
  <div
    ref="root"
    class="relative flex flex-col"
    :class="isAtEnd ? 'justify-end' : 'justify-start'"
    :style="{ height: `${100 * (cardCount - 1)}vh` }"
  >
    <div :class="isFixed ? 'fixed inset-x-0 top-0' : 'w-full'">
      <DefaultGrid>
        <div ref="cards" class="relative col-span-full">
          <div
            v-for="({ body, heading, largeText }, index) in props.cards"
            :key="index"
            class="inset-x-0 py-12 md:py-14 xl:py-20"
            :class="index === cardCount - 1 ? 'relative' : 'absolute'"
            :style="getWrapperStyles(index)"
          >
            <div class="w-full" :style="getPadding(index)">
              <div
                class="col-span-full flex min-h-[460px] flex-col items-center justify-center space-y-10 rounded-3xl px-6 text-center md:min-h-[680px] md:px-20 lg:min-h-[640px]"
                :class="getCardBackgroundClass(index)"
              >
                <h3 class="flex flex-col space-y-4 md:space-y-10">
                  <span
                    class="type-heading-4 font-medium uppercase"
                    v-text="heading"
                  />
                  <span
                    class="type-heading-jumbo type-font-salt"
                    v-text="largeText"
                  />
                </h3>
                <p class="mx-auto max-w-3xl leading-[1.5em]" v-text="body" />
              </div>
            </div>
          </div>
        </div>
      </DefaultGrid>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LargeScrollCards } from '#payload/types'

interface LargeScrollCardsProps {
  cards: LargeScrollCards
}

const props = defineProps<LargeScrollCardsProps>()
const cardCount = computed(() => props.cards.length)

const { getCardBackgroundClass } = useGlobalsStore()

const root = ref<HTMLElement | null>(null)
const cards = ref<HTMLElement | null>(null)
const { top, height } = useElementBounding(root)
const { height: cardsHeight } = useElementBounding(cards)
const { height: windowHeight } = useWindowSize()

const progress = computed(() =>
  Math.max(0, Math.min(1, -top.value / (height.value - cardsHeight.value))),
)

const isAtEnd = computed(() => progress.value === 1)
const isFixed = computed(() => progress.value > 0 && !isAtEnd.value)

const hasMounted = ref(false)

onMounted(() => {
  hasMounted.value = true
})

const { md, xl } = useTailwindBreakpoints()

const getWrapperStyles = (index: number) => {
  const progressSegmentSize = 1 / (cardCount.value - 1)
  const start = (index - 1) * progressSegmentSize
  const relativeProgress = (progress.value - start) / progressSegmentSize

  const translateY = Math.max(
    0,
    Math.min(windowHeight.value, (1 - relativeProgress) * windowHeight.value),
  )

  return hasMounted.value
    ? {
        transform: `translate3d(0, ${translateY}px, 0)`,
        zIndex: index + 1,
      }
    : {}
}

const getPadding = (index: number) => {
  let paddingBasis = 40

  if (xl.value) {
    paddingBasis = 64
  } else if (md.value) {
    paddingBasis = 56
  }

  return hasMounted.value
    ? {
        paddingTop: `${index * paddingBasis}px`,
      }
    : {}
}
</script>
