<template>
  <div
    ref="root"
    class="relative flex flex-col"
    :class="isAtEnd ? 'justify-end' : 'justify-start'"
    :style="{ height: `${100 * (cardCount - 1)}vh` }"
  >
    <div :class="isFixed ? 'fixed inset-x-0 top-0' : 'w-full'">
      <DefaultGrid>
        <div ref="cards" class="step-cards relative col-span-full">
          <div
            v-for="({ icon, heading, body }, index) in props.cards"
            :key="index"
            class="inset-x-0 py-12 md:py-14 xl:py-20"
            :class="index === cardCount - 1 ? 'relative' : 'absolute'"
            :style="getWrapperStyles(index)"
          >
            <div
              class="step-card mx-auto flex max-w-6xl"
              :class="alignmentClasses[index]"
              :style="getPadding(index)"
            >
              <div
                class="mx-auto flex h-[370px] flex-col items-center justify-center rounded-2xl px-6 py-8 text-center md:col-start-2 md:col-end-8 md:h-[530px] md:w-3/4 md:rounded-3xl lg:mx-0 lg:w-1/2"
                :class="[getCardBackgroundClass(index), getCardClasses(index)]"
              >
                <PayloadIcon
                  class="mb-7 h-20 md:h-24"
                  :class="iconClasses[index]"
                  :icon="icon"
                />
                <h3
                  class="type-heading-4 md:type-heading-3 mb-3 font-medium before:content-[counter(step-cards)'._']"
                  v-text="heading"
                />
                <p class="leading-[1.5em]" v-text="body" />
              </div>
            </div>
          </div>
        </div>
      </DefaultGrid>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SmallScrollCards } from '#payload/types'

interface SmallScrollCardsProps {
  cards: SmallScrollCards
}

const props = defineProps<SmallScrollCardsProps>()
const cardCount = computed(() => props.cards.length)

const { getCardBackgroundClass } = useGlobalsStore()
const iconClasses = ['text-purple', 'text-yellow', '']

const root = ref<HTMLElement | null>(null)
const cards = ref<HTMLElement | null>(null)
const { top, height } = useElementBounding(root)
const { height: cardsHeight } = useElementBounding(cards)

const progress = computed(() =>
  Math.max(0, Math.min(1, -top.value / (height.value - cardsHeight.value))),
)

const isAtEnd = computed(() => progress.value === 1)
const isFixed = computed(() => progress.value > 0 && !isAtEnd.value)

const hasMounted = ref(false)

onMounted(() => {
  hasMounted.value = true
})

const { md, lg } = useTailwindBreakpoints()

const getWrapperStyles = (index: number) => {
  const progressSegmentSize = 1 / (cardCount.value - 1)
  const start = (index - 1) * progressSegmentSize
  const relativeProgress = (progress.value - start) / progressSegmentSize

  const translateY = Math.max(0, Math.min(100, (1 - relativeProgress) * 100))

  return hasMounted.value
    ? {
        transform: `translate3d(0, ${translateY}vh, 0)`,
        zIndex: index + 1,
      }
    : {}
}

const alignmentClasses = [
  'lg:justify-start',
  'lg:justify-center',
  'lg:justify-end',
]

const getPadding = (index: number) => {
  let paddingBasis = 40

  if (lg.value) {
    paddingBasis = 0
  } else if (md.value) {
    paddingBasis = 80
  }

  return hasMounted.value
    ? {
        paddingTop: `${index * paddingBasis}px`,
      }
    : {}
}

const getCardClasses = (index: number) => {
  const classes = ['-rotate-3', 'rotate-3 lg:rotate-0', '-rotate-3 lg:rotate-3']

  return hasMounted ? classes[index] : ''
}
</script>

<style scoped lang="postcss">
.step-cards {
  counter-reset: step-cards;
}

.step-cards h3 {
  counter-increment: step-cards;
}
</style>
