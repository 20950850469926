<template>
  <div class="relative">
    <h1 class="flex flex-col items-center space-y-10 text-center font-medium">
      <span class="type-heading-4 uppercase"
        >Hit pause on smartphones and</span
      >
      <span class="relative">
        <span class="large-text type-font-salt-ss02 leading-[1em] text-charcoal"
          ><span style="transform: rotate(2deg)">cyber</span><br />
          <span style="transform: rotate(-10deg)">bullying</span></span
        >
        <span class="icons pointer-events-none absolute inset-0">
          <PayloadIcon
            class="absolute bottom-[0.55em] left-[0.35em] h-[0.45em] text-red-light"
            icon="SvgIconExplosion"
          />
          <PayloadIcon
            class="absolute bottom-[1.7em] left-[1.15em] h-[0.25em] text-blue-dark"
            icon="SvgIconTypingIndicator"
          />
          <PayloadIcon
            class="absolute bottom-[1.1em] left-[3.2em] h-[0.25em] rotate-12 text-yellow"
            icon="SvgIconSnapchat"
          />
          <PayloadIcon
            class="absolute -bottom-[0.15em] left-[2.7em] h-[0.3em] rotate-[10deg] text-red-light"
            icon="SvgIconNotification"
          />
        </span>
      </span>
    </h1>
  </div>
</template>

<style scoped lang="postcss">
span {
  @apply inline-block;
}
</style>
