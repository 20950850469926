<template>
  <div class="relative">
    <h1
      class="flex flex-col items-center space-y-10 text-center font-medium md:space-y-20"
    >
      <span class="type-heading-4 uppercase"
        >Hit pause on smartphones and</span
      >
      <span class="relative">
        <span
          class="large-text type-font-salt -rotate-6 leading-[0.85em] text-charcoal"
          ><span class="pl-[0.3em]">doom</span><br />
          <span>scrolling</span></span
        >
        <span class="icons pointer-events-none absolute inset-0">
          <PayloadIcon
            class="absolute bottom-[0.6em] left-[0.25em] h-[0.39em] -rotate-[24deg] text-yellow-light"
            icon="SvgIconSmiley"
          />
          <PayloadIcon
            class="absolute bottom-[1.6em] left-[1.66em] h-[0.35em] text-red-light"
            icon="SvgIconAttentionH"
          />
          <PayloadIcon
            class="absolute bottom-[1em] left-[3.68em] h-[0.35em] rotate-[15deg] text-blue-dark"
            icon="SvgIconAttentionC"
          />
          <PayloadIcon
            class="absolute -bottom-[0.475em] left-[0.57em] h-[0.275em] text-pink-dark"
            icon="SvgIconFlowerPetals"
          />
          <PayloadIcon
            class="absolute -bottom-[0.275em] left-[3.21em] h-[0.3em] rotate-3 text-purple"
            icon="SvgIconPoo"
          />
        </span>
      </span>
    </h1>
  </div>
</template>

<style scoped lang="postcss">
span {
  @apply inline-block;
}
</style>
