<template>
  <div class="relative">
    <h1
      class="flex flex-col items-center space-y-10 text-center font-medium md:space-y-20"
    >
      <span class="type-heading-4 uppercase"
        >Hit pause on smartphones and</span
      >
      <span class="relative">
        <span
          class="large-text type-font-salt-ss02 -rotate-6 leading-[0.85em] text-charcoal"
          ><span>harmful</span><br />
          <span>content</span></span
        >
        <span class="icons pointer-events-none absolute inset-0">
          <PayloadIcon
            class="absolute -left-[0.2em] bottom-[0.3em] h-[0.39em] -rotate-[24deg] text-pink-dark"
            icon="SvgIconLipstick"
          />
          <PayloadIcon
            class="absolute bottom-[1.3em] left-[0.35em] h-[0.35em] text-blue-dark"
            icon="SvgIconEarth"
          />
          <PayloadIcon
            class="absolute bottom-[1.2em] left-[3.65em] h-[0.3em] rotate-[15deg] text-red-light"
            icon="SvgIconLol"
          />
          <PayloadIcon
            class="absolute -bottom-[0.275em] left-[3.21em] h-[0.3em] rotate-3 text-yellow-light"
            icon="SvgIconConfusionB"
          />
        </span>
      </span>
    </h1>
  </div>
</template>

<style scoped lang="postcss">
span {
  @apply inline-block;
}
</style>
